<template>
  <div>
    <!-- <this-header /> -->
    <b-overlay
      :show="Loading"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <p id="cancel-label">
            กรุณารอสักครู่...
          </p>
        </div>
      </template>
      <div class="anan-set-nav-conttainer mt-150">
        <div class="row">

          <div class="col-md-2 col-lg-2 col-sm-12">

            <router-link :to="{name : 'new-pages'}">
              <b-button
                class="btn btn-gradient-primary mb-1 mr-50 mr-50 d-md-none"
              >
                <i class="fas fa-arrow-left mr-25" /> {{ $t('key-126') }}
              </b-button>
            </router-link>

            <b-button
              class="btn btn-gradient-primary mb-1 mr-50 d-md-none"
              @click="$router.push({ name: 'customer-refill-add' })"
            >
              <i class="far fa-plus mr-25" /> {{ $t("topupSystem") }}
            </b-button>
            <side-bar class="d-none d-md-block" />
          </div>

          <div class="col-md-10 col-lg-10 col-sm-12">
            <div class="card position-relative">
              <button
                class="btn btn-primary btn-add d-none d-md-block"
                @click="$router.push({ name: 'customer-refill-add' })"
              >
                <i class="far fa-plus mr-25" /> {{ $t("topupSystem") }}
              </button>

              <div
                class="nav-panel anan-tabs anan-tabs-line anan-tabs-normal anan-tabs-top"
              >
                <div class="anan-tabs__nav">
                  <div
                    class="anan-tabs__nav-warp px-2"
                    style="margin-top: 5px"
                  >
                    <div
                      class="anan-tabs__nav-tabs"
                      style="transform: translateX(0px)"
                    >
                      <div
                        class="anan-tabs__nav-tab"
                        :class="{ active: isActive === 'all' }"
                        style="white-space: normal"
                        @click="
                          GetData((status = null), (search_val = null)),
                          (isActive = 'all')
                        "
                      >
                        <span> {{ $t("all") }} </span>
                      </div>

                      <div
                        class="anan-tabs__nav-tab"
                        :class="{ active: isActive === 'cancel' }"
                        style="white-space: normal"
                        @click="
                          GetData((status = 'cancel'), (search_val = null)),
                          (isActive = 'cancel')
                        "
                      >
                        <span> {{ $t("cancel") }} </span>
                      </div>

                      <div
                        class="anan-tabs__nav-tab"
                        :class="{ active: isActive === 'waiting' }"
                        style="white-space: normal"
                        @click="
                          GetData((status = 'waiting'), (search_val = null)),
                          (isActive = 'waiting')
                        "
                      >
                        <span> {{ $t("pendingReview") }} </span>
                      </div>

                      <div
                        class="anan-tabs__nav-tab"
                        :class="{ active: isActive === 'success' }"
                        style="white-space: normal"
                        @click="
                          GetData((status = 'success'), (search_val = null)),
                          (isActive = 'success')
                        "
                      >
                        <span> {{ $t("operationCompleted") }} </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-between align-items-center px-2">
                <div>
                  <h3 class="font-weight-bolder">
                    {{ $t("topupSystem") }}
                  </h3>
                </div>

                <div class="d-flex align-items-center">
                  <div
                    class="anan-input__inner anan-input__inner--normal pl-75 pt-25 pb-25 pr-25"
                  >
                    <input
                      v-model="search_val"
                      type="text"
                      placeholder="ค้นหา"
                      class="anan-input__input"
                      @keyup.enter="GetData()"
                    >
                    <div class="anan-input__suffix">
                      <button
                        type="button"
                        class="anan-button anan-button--normal anan-button--primary"
                        @click="GetData"
                      >
                        <i class="fal fa-search" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-2">
                <b-table
                  striped
                  hover
                  responsive
                  :per-page="perPage"
                  :items="items"
                  :fields="fields"
                  show-empty
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner
                        label="กำลังโหลด..."
                        variant="danger"
                      />
                    </div>
                  </template>

                  <template #empty>
                    <div class="text-center">
                      <img
                        src="@/assets/images/anan-img/empty/empty.png"
                        alt="empty"
                        width="180px"
                      >
                      <p>No Data</p>
                    </div>
                  </template>

                  <template #cell(sequence)="data">
                    {{ data.item.itemnumber_text }}
                  </template>

                  <template #cell(created_at)="data">
                    {{ time(data.item.created_at) }}
                  </template>
                  <template #cell(date_approve)="data">
                    {{
                      data.item.date_approve ? time(data.item.date_approve) : "-"
                    }}
                  </template>

                  <template #cell(amount)="data">
                    ฿ {{ Commas(data.item.amount) }}
                  </template>

                  <template #cell(fileimg)="data">
                    <img
                      v-if="data.item.fileimg"
                      :src="GetImg('refillslip',data.item.fileimg)"
                      alt="img-document"
                      class="border"
                      height="40"
                      @click="showPicture(data.item.fileimg)"
                    >
                    <span v-else>-</span>
                  </template>

                  <!-- <template #cell(qrcode)="data">
                  <feather-icon
                    v-if="data.item.qrcode"
                    icon="AlertCircleIcon"
                    size="18"
                    class="text-success"
                  />
                  <feather-icon
                    v-else
                    icon="AlertCircleIcon"
                    size="18"
                    class="text-danger"
                  />
                </template> -->

                  <template #cell(status)="data">
                    <span
                      v-if="data.item.status === 'waiting'"
                      class="badge badge-warning"
                    >
                      {{ $t("pendingReview") }}
                    </span>

                    <span
                      v-if="data.item.status === 'success'"
                      class="badge badge-success"
                    >
                      {{ $t("operationCompleted") }}
                    </span>
                    <span
                      v-if="data.item.status === 'cancel'"
                      class="badge badge-danger"
                    >
                      {{ $t("cancel") }}
                    </span>
                  </template>

                  <template #cell(action)="data">
                    <feather-icon
                      icon="Edit3Icon"
                      class="mr-1 text-primary cursor-pointer"
                      @click="
                        $router.push({
                          name: 'customer-refill-view',
                          params: { id: data.item._id },
                        })
                      "
                    />
                    <h1>{{ data.item.id }}</h1>
                  </template>
                </b-table>

                <div class="demo-spacing-0 d-flex justify-content-end">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mb-0"
                    @input="GetData(status, search_val)"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>

    <vue-image-lightbox-carousel
      ref="lightbox"
      :show="showLightbox"
      :images="images"
      :show-caption="false"
      @close="closeBox()"
    />
  </div>
</template>

<script>
import { BTable, BPagination, BOverlay } from 'bootstrap-vue'
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
// import moment from 'moment-timezone'
import moment from 'moment-timezone'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'
import SideBar from '../component/SideBar.vue'

export default {
  name: 'LineNotify',
  components: {
    BTable,
    BPagination,
    SideBar,
    BOverlay,
    VueImageLightboxCarousel,
    // ThisHeader,
  },
  data() {
    return {
      images: [],
      showLightbox: false,
      isActive: 'all',
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      status: null,
      search_val: null,
      Loading: false,
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'sequence',
          label: this.$t('orderNumber'),
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'created_at',
          label: this.$t('transactionDate'),
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        // {
        //   key: 'file_date',
        //   label: this.$t('transactionDate'),
        //   sortable: false,
        //   thStyle: { width: '10%' },
        //   thClass: 'text-center',
        //   tdClass: 'text-center',
        // },
        {
          key: 'date_approve',
          label: this.$t('approvalDate'),
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'amount',
          label: this.$t('quantity'),
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'name_bank',
          label: this.$t('bank'),
          sortable: false,
          thStyle: { width: '15%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'fileimg',
          label: this.$t('evidence'),
          sortable: false,
          thStyle: { width: '15%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        // {
        //   key: 'qrcode',
        //   label: this.$t('qrCode'),
        //   sortable: false,
        //   thStyle: { width: '15%' },
        //   thClass: 'text-center',
        //   tdClass: 'text-center',
        // },
        {
          key: 'status',
          label: this.$t('status'),
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'action',
          label: this.$t('manage'),
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ]
    },
  },
  mounted() {
    this.GetData()
  },
  methods: {
    async GetData() {
      this.Loading = true
      try {
        const params = {
          status: this.status,
          search_val: this.search_val,
          page: this.currentPage,
          perPage: this.perPage,
        }
        const { data: res } = await this.$http.get('/Refill/index', { params })
        // res.data.map(ele => {
        //   ele.fileimg = null
        // })
        this.items = res.data
        this.totalRows = res.total
        this.Loading = false
        // this.ShowIMG()
      } catch (e) {
        console.log(e)
        this.Loading = false
      }
    },
    ShowIMG() {
      const Id = this.items.map(ele => ele._id)
      const params = {
        id: Id,
      }
      this.$http.get('/Refill/Getdataimg', { params })
        .then(response => {
          response.data.forEach((data, index) => {
            this.items[index].fileimg = data.fileimg
          })
        })
        .catch(err => console.log(err))
        // console.debug(params)
    },
    time(data) {
      return moment(data).format('DD/MM/YYYY HH:mm:ss')
    },
    GetImg(pathfile, filename) {
      const matches = filename.match(/^data:image\/([A-Za-z-+\/]+);base64,(.+)$/)
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`
      }
      return filename
    },
    showPicture(picture) {
      this.showLightbox = true
      const img = { path: this.GetImg('refillslip', picture) }
      this.images.push(img)
      // this.$swal({
      //   imageUrl: picture,
      //   imageWidth: 400,
      //   imageHeight: 400,
      //   imageAlt: 'Custom image',
      //   confirmButtonText: 'ปิด',
      // })
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
